<style lang="scss" scoped>
</style>

<template>
  <!-- 用户新增/编辑 -->
  <section class="page-child-contianer">
    <el-form
      class="page-form"
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="84px"
    >
      <!-- <el-form-item label="上级部门:">
            <el-select v-model="formData.pid" disabled>
              <el-option label="无" :value="0" />
              <el-option
                v-for="(item, index) in branchListSearch"
                :key="index + 100"
                :label="item.name"
                :value="item.id"
              >
                <p>
                  <template v-if="item.hierarchy > 0">
                    <span
                      class="hierarchy iconfont"
                      v-for="num in item.hierarchy"
                      :key="`${num}${item.id}`"
                    >
                      &#xe603;
                    </span>
                  </template>
                  {{ item.name }}
                </p>
              </el-option>
            </el-select>
          </el-form-item> -->
      <el-form-item label="部门名称:" prop="name">
        <el-input v-model="formData.name" placeholder="请输入部门名称" />
      </el-form-item>

      <el-form-item label="描述:">
        <el-input
          v-model="formData.desc"
          placeholder="请输入描述"
          type="textarea"
          :rows="5"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </el-form-item>

      <el-form-item label="工单:">
        <el-checkbox-group v-model="formData.checkList">
          <el-checkbox
            v-for="item in workOrderList"
            :key="item.id"
            :label="item.id + ''"
          >
            {{ item.name }}【 {{ item.desc }}】
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input-number v-model="formData.sort" :min="1" />
      </el-form-item>
      <el-form-item>
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"> 确 定 </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import ueditorcom from "@/components/ueditorcom";
import { branchUpdate, branchAdd } from "@api/branch";
import { getWorkOrderList } from "@api/workOrder";
export default {
  name: "branchForm",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    ueditorcom,
  },
  data() {
    return {
      id: null, //修改用户id
      formData: {
        sort: 200,
        checkList: [],
      }, //表单存储数据

      workOrderList: [], //工单（类型）列表

      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
      }, //表单校验规则
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDetail();
    }
    let res = await getWorkOrderList();
    this.workOrderList = res.data;
  },
  mounted() {},
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据(部门详情)
       */
      let res = await branchUpdate({ id: this.id }, "get");
      
      this.formData.checkList = res.data.work_order_ids_arr;
      // 数据请求返回成功后打开弹窗
      this.formDialogVisible = true;
      this.$nextTick(() => {
        // 注意看这里
        this.formData = {
          ...this.formData,
          ...res.data,
        };
      });

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },
    /** 提交用户信息*/
    handleSubmit() {
      this.formData = {
        ...this.formData,
        work_order_ids: this.formData.checkList.toString(),
      };
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口
           */
          // delete this.formData.delete_time;
          // delete this.formData.update_time;
          if (this.id) {
            // 存在id，执行编辑请求
            var res = await branchUpdate(this.formData, "post");
          } else {
            // 不存在id，执行添加请求
            var res = await branchAdd(this.formData);
          }
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            // 请求后，不论是否成功，调用方法关闭弹窗

            this.handleCancle();
          }
        }
      });
    },
    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>